import React, { useEffect } from "react"

const BeforeUnloadModal = ({ onBeforeUnload }) => {

    useEffect(() => {
        console.log("beforeUnloadModal starting")
        const handleOnBeforeUnload = (event) => {
            console.log("beforeUnload event triggered")
            event.preventDefault()
            if (typeof onBeforeUnload === "function") {
                onBeforeUnload()
            }
        }
        // window.addEventListener("beforeunload", handleOnBeforeUnload)
        window.addEventListener("visibilitychange", handleOnBeforeUnload)

        return () => {
            console.log("beforeUnloadModal unloading")
            // window.removeEventListener("beforeunload", handleOnBeforeUnload)
            window.removeEventListener("visibilitychange", handleOnBeforeUnload)
        }
    }, [])

    return <></>
};

export default BeforeUnloadModal;
