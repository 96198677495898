import { useSelector } from "react-redux"
import "./Notification.css"
import { IoMdClose } from "react-icons/io";
import { BsFillExclamationDiamondFill } from "react-icons/bs";
import { notify } from "../../services/notificationServices";

export default function Notification() {
    const notification = useSelector((state) => state.notificationState)

    // Only returning for errors now. warning and success not shown.
    if (notification.display) {
        return (
            <div
                id="notif-div"
                className={`container notification ${notification.display ? 'show' : 'hide'}`}
            >
                <div className="d-flex justify-content-between p-2">
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <BsFillExclamationDiamondFill />
                        </div>
                        <div className="align-items-center ps-2">
                            {notification.msg}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pe-auto">
                        <IoMdClose id="notif-close-icon" className="pe-auto" onClick={() => {
                            notify.close()
                        }} />
                    </div>
                </div>
            </div >
        )
    }
}