import axios from 'axios'

// Add error catching for the methods
export const getQuestionsByTopic = async ({ topic, uid, numOfQns }, { rejectWithValue }) => {
    try {
        const response = await axios.get("/test", {
            params: {
                topic, uid, numOfQns
            }
        })
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const getAnswersForTest = async ({ tsid, uid }, { rejectWithValue }) => {
    try {
        const requestBody = {
            tsid
        }
        const response = await axios.post("/test/answer", requestBody)
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message);
    }
}

export const getExamPaperByTopic = async ({ topic, uid }, { rejectWithValue }) => {
    try {
        const response = await axios.get("/exam", {
            params: {
                topic, uid
            }
        })
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const submitExamAnswer = async ({ esid, examResponses }, { rejectWithValue }) => {
    try {
        const requestBody = {
            esid, examResponses
        }
        const response = await axios.post("/exam/answer", requestBody)
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const getConfigs = async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get("/configs/abc", {});
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data)
    }
}

export const retrieveUserProfile = async (_, { rejectWithValue }) => {
    try {
        const response = await axios.post("/user/login")
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const reportQuestion = async ({ uid, qid, comment }, { rejectWithValue }) => {
    try {
        const requestBody = {
            uid, qid, comment
        }
        const response = await axios.post("/report", requestBody)
        console.log("response from backend: ", response)
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const getSurveyQuestionsByCode = async ({ code }, { rejectWithValue }) => {
    try {
        const response = await axios.get("/survey/code/" + code)
        return response.data.payload[0].questions

    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const submitSurveyResponse = async ({ uid, code, surveyResponses, credit }, { rejectWithValue }) => {
    try {
        const requestBody = {
            uid, code, surveyResponses, credit
        }
        const response = await axios.post("/survey/code/" + code, requestBody)
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}

export const submitContactForm = async ({ contactForm }, { rejectWithValue }) => {
    try {
        const requestBody = {
            contactForm
        }
        const response = await axios.post("/contact", requestBody)
        return response.data
    } catch (e) {
        return rejectWithValue(e.response?.data || e.message)
    }
}