import { initializeApp } from "@firebase/app"
import { getAuth, connectAuthEmulator } from "@firebase/auth"
import { store } from "../redux/store"
import { loggedIn, loggedOut, setToken } from "../redux/slice/authSlice";
import { getUserProfile } from "../redux/thunks/fetchDataThunk";

const firebaseConfig = {
    apiKey: "AIzaSyA1LqBcJDAbNZJAzPoZX7qz0kHww0iShbk",
    authDomain: "fir-test-5c96f.firebaseapp.com",
    projectId: "fir-test-5c96f",
    storageBucket: "fir-test-5c96f.appspot.com",
    messagingSenderId: "41451836622",
    appId: "1:41451836622:web:4cc4327947cc3e767c2c9f",
    measurementId: "G-85S4TM3KFK"
};


export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

// if (window.location.hostname == "localhost") {
//     connectAuthEmulator(auth, "http://localhost:9099")
// }

export const getAccessToken = async () => {
    return auth.currentUser.getIdToken();
}

export const signOut = async () => {
    auth.signOut().then(() => {
        console.log("Signed out successfully");
    }).catch((err) => {
        console.error("An error occurred while signing out: ", err);
    });
}

// Monitor auth state
auth.onAuthStateChanged((user) => {
    console.log("auth state changed => ", user)
    if (user) {
        store.dispatch(loggedIn())
        // store.dispatch(setToken(user.accessToken))
        store.dispatch(getUserProfile())
    } else {
        store.dispatch(loggedOut())
        // remove accesstoken, uid
        // send uid to backend to revoke
    }
})