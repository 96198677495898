import { useSelector } from "react-redux"
import "./LoadingModal.css"
import logo from "../../images/logo.svg"



export function LoadingModal() {
    const isLoading = useSelector((state) => state.loadingState.isLoading)

    const renderModal = () => {
        return (
            isLoading ?
                <div id="loading-modal">
                    <div>
                        <div className="d-flex justify-content-center mb-2">
                            <img id="loading-icon" src={logo} />
                        </div>
                        <p className="text-center">Loading</p>
                    </div>
                </div >
                : null)

    }

    return renderModal()

}