import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { resetState, setCurrentSurvey } from "../../redux/slice/surveyAppSlice";
import { getSurveyQuestions } from "../../redux/thunks/fetchDataThunk";

export default function TitleSurveyCard({ data, disabled = false }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const handleButtonClick = () => {
        dispatch(resetState())
        dispatch(setCurrentSurvey(data))
        dispatch(getSurveyQuestions({ code: data.code }))
            .unwrap()
            .then(() => {
                navigate("/survey")
            })
    }

    return (
        <button
            className={`action-card-div ${disabled ? "disable" : ""
                } d-flex flex-column gap-1`}
            onClick={handleButtonClick}
        >
            <h4 className="action-card-title card-title">{data.title}</h4>
            <span className="reward-details">{data.rewards ? "Rewards: " + data.rewards : null}</span>
            <p className="mt-1">{data.description}</p>
        </button>
    )
}