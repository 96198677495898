import { notifyClose, notifyError, notifyResetMessage, notifySuccess, notifyWarning } from "../redux/slice/notificationSlice"
import { store } from "../redux/store"

export const notify = {
    success: (response) => {
        store.dispatch(notifySuccess(response))
    },
    error: (response) => {
        store.dispatch(notifyError(response))
    },
    warning: (response) => {
        store.dispatch(notifyWarning(response))
    },
    close: () => {
        store.dispatch(notifyClose())
        setTimeout(() => {
            store.dispatch(notifyResetMessage())
        }, 500)
    }
} 