import { useLocation } from "react-router-dom";
import { ButtonTypeOne } from "../../components/buttons/ButtonTypeOne";
import ErrorImg from "../../images/error-logo.svg";
import "./ErrorPage.css";
import { useEffect } from "react";
import { signOut } from "../../services/firebase";
import { useNavigateHome } from "../../services/utilityFunctions";

export default function ErrorPage() {
  const location = useLocation();
  const navigateHome = useNavigateHome();

  useEffect(() => {
    if (location?.state?.status === 401 || location?.state?.status === 403) {
      const signoutTimeout = setTimeout(() => {
        signOut();
      }, 5000);
  
      return () => clearTimeout(signoutTimeout);
    }
  }, []);

  return (
    <div
      id="error-page"
      className="d-flex flex-column align-items-center gap-3"
    >
      <div className="d-flex justify-content-center align-items-center mt-5">
        <img id="error-img" src={ErrorImg} alt="Page not found" />
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <h4 className="page-title">Page not found.</h4>
      </div>
      <div className="mt-3">
        <ButtonTypeOne text="RETURN HOME" action={navigateHome} />
      </div>
    </div>
  );
}
