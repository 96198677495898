import { renderQuestionsComponent } from "../services/utilityFunctions";

export default function QuestionPagination({ testQuestions, testResponses, currentQns, action }) {

    const qnsComponentArr = renderQuestionsComponent(testQuestions, testResponses, action);

    return (
        <>
            {
                qnsComponentArr.map((qns, idx) => {
                    if (currentQns.includes(idx)) {
                        return qns
                    }
                })
            }
        </>
    )
}