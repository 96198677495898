import { useNavigate } from "react-router-dom";
import { ButtonTypeThree } from "../../components/buttons/ButtonTypeThree";
import "./SectionOne.css";
export default function SectionOne() {
  const navigate = useNavigate()
  return (
    <div id="section-one" className="d-flex flex-column align-items-center gap-2">
      <h1 id="landing-page-title" className="text-center mb-0 page-title">
        Master Healthcare Concepts
      </h1>
      <p id="landing-page-description" className="text-center mb-0 page-description">
        At capsulebank, explore over thousands of questions to deepen
        your understanding and master a spectrum of key concepts.
      </p>
      {/* <button className="action-btn d-none d-md-inline-block">Get started &gt;</button> */}
      <div className="d-none d-md-inline-block" >
        <ButtonTypeThree text="Get started &gt;" action={() => navigate("/user")} />
      </div>
    </div>
  );
}
