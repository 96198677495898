import "./SurveyApp.css"
import { useSelector, useDispatch } from "react-redux"
import { setSurveyResponseByQID, resetState } from "../../redux/slice/surveyAppSlice"
import { getUserProfile, submitSurveyResponses } from "../../redux/thunks/fetchDataThunk"
import { ButtonTypeThree } from "../../components/buttons/ButtonTypeThree"
import { SubmitBtnWithConfirmation } from "../../components/buttons/SubmitBtnWithConfirmation"
import { useNavigateHome, sortSurveyByQid, renderQuestionsComponent } from "../../services/utilityFunctions"

export default function SurveyApp() {
    const navigateHome = useNavigateHome()
    const dispatch = useDispatch()
    const uid = useSelector((state) => state.auth.profile.uid)
    const { isLoading } = useSelector((state) => state.loadingState);
    const surveyAppState = useSelector((state) => state.surveyApp)
    const currentSurvey = surveyAppState.currentSurvey
    const questions = surveyAppState.questions
    const surveyResponses = surveyAppState.surveyResponse
    const sortedSurveyQuestions = questions?.toSorted(sortSurveyByQid)

    const submitSurvey = () => {
        const code = currentSurvey.code
        const credit = currentSurvey.rewards
        dispatch(submitSurveyResponses({ uid, code, surveyResponses, credit }))
            .unwrap()
            .then(() => {
                dispatch(resetState())
                navigateHome()
                dispatch(getUserProfile())
            })
    }

    const renderForwardButton = () => {
        if (Object.keys(surveyResponses).length == sortedSurveyQuestions.length) {
            return <SubmitBtnWithConfirmation action={submitSurvey} disabled={isLoading}/>
        } else {
            return <ButtonTypeThree text={"SUBMIT"} disabled />
        }
    }

    return (
        <div className="survey-app-container">
            <div className="survey-content">
                <h4>{currentSurvey.title}</h4>
                {sortedSurveyQuestions && renderQuestionsComponent(sortedSurveyQuestions, surveyResponses, setSurveyResponseByQID)}
            </div>
            <div className="submit-btn">
                {renderForwardButton()}
            </div>
        </div>
    )

}