import { Modal } from "react-bootstrap";
import { ButtonTypeThree } from "./ButtonTypeThree";
import { ButtonTypeTwo } from "./ButtonTypeTwo";
import { useState } from "react";

export function SubmitBtnWithConfirmation({action, disabled=false}) {
    const [showModal, setShowModal] = useState(false)

    const handleConfirm = () =>{
        action();
        closeModal();
    }

    const closeModal = () => {
        setShowModal(false);
    }

    return(
        <>
            <ButtonTypeThree 
            text={"SUBMIT"}
            action={() => setShowModal(true)}
            disabled={disabled}
            />
            <Modal centered show={showModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Your application is ready to submit. Would you like to continue?</span>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <ButtonTypeThree 
                        text="CONFIRM" 
                        action={handleConfirm}
                    /> 
                    <ButtonTypeTwo
                        text="CANCEL"
                        action={closeModal}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
