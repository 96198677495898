import "./Herobanner.css";
import Banner from "../../images/banner.svg";

function Herobanner() {
  return (
    <div id="hero-banner" className="d-flex justify-content-center mt-2">
      <img src={Banner} alt="Hero Banner" className="hero-banner-img" />
    </div>
  );
}

export default Herobanner;
