
import Herobanner from "./Herobanner"
import SectionOne from "./SectionOne"
import SectionTwo from "./SectionTwo"

export default function LandingPage() {

    return (
        <div id="main-page" className="p-2">
            <SectionOne />
            <Herobanner />
            <SectionTwo />

            {/* <SectionOne />
            <SectionOne /> */}
        </div>
    )
}