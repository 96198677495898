import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit"
import { getPracticeModeTest, submitExamModeAnswer, getPracticeModeAnswer, getExamModeTest, getExamModeAnswer, getSurveyQuestions } from "../thunks/fetchDataThunk"
import { getConfigsForApp } from "../thunks/fetchDataThunk"

const initialState = {
    isLoading: false
}

const loadingModalSlice = createSlice({
    name: 'loadingModal',
    initialState,
    reducers: {
        setLoading(state, action) {
            console.log("called")
            state.isLoading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPracticeModeTest.pending, (state) => {
                console.log('questions is loading')
            })
            .addCase(getPracticeModeTest.fulfilled, (state, action) => {
                console.log('questions is fullfilled')
            })
            .addCase(getPracticeModeTest.rejected, (state) => {
                console.log('questions is rejected')
            })
            .addMatcher(isPending(getConfigsForApp, getPracticeModeTest, getPracticeModeAnswer, getExamModeTest, submitExamModeAnswer, getSurveyQuestions), (state) => {
                state.isLoading = true
            })
            .addMatcher(isFulfilled(getConfigsForApp, getPracticeModeTest, getPracticeModeAnswer, getExamModeTest, submitExamModeAnswer, getSurveyQuestions), (state) => {
                state.isLoading = false
            })
            .addMatcher(isRejected(getConfigsForApp, getPracticeModeTest, getPracticeModeAnswer, getExamModeTest, submitExamModeAnswer, getSurveyQuestions), (state) => {
                state.isLoading = false
            })
    }
})

export const { setLoading } = loadingModalSlice.actions
export default loadingModalSlice.reducer