import { createSlice } from "@reduxjs/toolkit";
import { getUserProfile } from "../thunks/fetchDataThunk";

const initialState = {
    isLoggedIn: false,
    isLoading: true,
    token: "",
    profile: {}
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loggedIn: (state) => {
            state.isLoading = false;
            state.isLoggedIn = true;
        },
        loggedOut: (state) => {
            state.isLoading = false;
            state.isLoggedIn = false
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        reduceCreditCount: (state, action) => {
            state.profile.credit = state.profile.credit - 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProfile.fulfilled, (state, action) => {
            state.profile = action.payload.payload
        })
    }
})

export const { loggedIn, loggedOut, setToken, reduceCreditCount } = authSlice.actions
export default authSlice.reducer