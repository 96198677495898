import { combineReducers } from "@reduxjs/toolkit";
import testAppSliceReducer from "./testAppSlice";
import practiceModeReducer from "./practiceModeSlice";
import examModeReducer from "./examModeSlice";
import timeModeReducer from "./timeModeSlice";
import quickModeReducer from "./quickModeSlice";
import challengeModeReducer from "./challengeModeSlice";
import authSliceReducer from "./authSlice";
import loadingModalSlice from "./loadingModalSlice";
import notificationSlice from "./notificationSlice";
import surveyAppSlice from "./surveyAppSlice";
import contactAppSlice from "./contactAppSlice";

const appReducer = combineReducers({
    testApp: testAppSliceReducer,
    practiceMode: practiceModeReducer,
    examMode: examModeReducer,
    timeMode: timeModeReducer,
    quickMode: quickModeReducer,
    challengeMode: challengeModeReducer,
    auth: authSliceReducer,
    loadingState: loadingModalSlice,
    notificationState: notificationSlice,
    surveyApp: surveyAppSlice,
    contactApp: contactAppSlice
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/loggedOut') {
        state = undefined;
        // set state = undefined to trigger default state in all slices
        // if need customise states for some to be default and others not, need export the initial states and set it as an object here
        /*
        state = {
          testApp: initialTestAppState,
          practiceMode: initialPracticeModeState,
        };
         */
    }
    return appReducer(state, action);
}

export default rootReducer;