import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ButtonTypeOne } from "../../components/buttons/ButtonTypeOne";
import { useNavigateHome } from "../../services/utilityFunctions";
import { submitContactResponse } from "../../redux/thunks/fetchDataThunk";
import { useDispatch, useSelector } from "react-redux";
import { resetSubmitStatus } from "../../redux/slice/contactAppSlice";
import { SubmitBtnWithConfirmation } from "../../components/buttons/SubmitBtnWithConfirmation";

export default function ContactApp() {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      contactType: "B",
      contactDescr: "",
    },
  });

  const dispatch = useDispatch();
  const navigateHome = useNavigateHome();
  const submitStatus = useSelector((state) => state.contactApp.submitSuccess)

  const onSubmit = handleSubmit((contactForm) => {
    dispatch(submitContactResponse({ contactForm }))
    reset();
  });

  return (
    <div
      id="contact-app-main-div"
      className="mx-4 mx-sm-0 d-flex flex-column gap-3"
    >
      <h3 id="contact-app-title-div" className="page-title">Contact Us</h3>
      <p id="contact-app-description" className="page-description">
        We would love to have your feedback and suggestions! Please report any
        bugs that you see to help us improve!
      </p>
      <form
        onSubmit={onSubmit}
        id="contact-app-form"
        className="d-flex flex-column gap-4"
      >
        <div id="contact-qns-div">
          <p className="mb-2 fw-bold">1. What do you want to let us know?</p>
          <div className="d-flex flex-column gap-2">
            <div className="form-check">
              <input
                {...register("contactType", { required: true })}
                className="form-check-input"
                type="radio"
                value="B"
              />
              <label htmlFor="contactType" className="form-check-label">
                Bugs
              </label>
            </div>
            <div className="form-check">
              <input
                {...register("contactType", { required: true })}
                className="form-check-input"
                type="radio"
                value="S"
              />
              <label htmlFor="contactType" className="form-check-label">
                Suggestions
              </label>
            </div>
          </div>
        </div>
        <div id="contact-qns-div" className="input-group">
          <label htmlFor="contactDescr" className="form-label fw-bold">
            2. Can you elaborate on the bug / suggestion*?
          </label>
          <textarea
            {...register("contactDescr", { required: true })}
            id="contactDescr"
            className="w-100 form-control"
            rows="5"
          ></textarea>
        </div>
        <div id="buttons-div" className="d-flex flex-column d-md-none gap-2">
          <SubmitBtnWithConfirmation
            action={onSubmit}
            disabled={!isValid || isSubmitting}
          />
          <ButtonTypeOne
            text="RETURN HOME"
            style={{ width: "100%" }}
            action={navigateHome}
          />
        </div>
        <div id="buttons-div" className="d-none d-md-flex justify-content-end gap-2">
          <SubmitBtnWithConfirmation
            action={onSubmit}
            disabled={!isValid || isSubmitting}
          />
          <ButtonTypeOne
            text="RETURN HOME"
            style={{ width: "100%" }}
            action={navigateHome}
          />
        </div>
      </form>
    </div>
  );
}
