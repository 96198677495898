import { useSelector } from "react-redux";
import ExamModeDo from "./ExamModeDo";
import ExamModeEnd from "./ExamModeEnd";
import ExamModeStart from "./ExamModeStart";

const switchPhase = (param) => {
    switch (param) {
        case 0:
            return <ExamModeStart />
        case 1:
            return <ExamModeDo />
        case 2:
            return <ExamModeEnd />
    }
}



export default function ExamMode() {
    const examModePhase = useSelector((state) => state.examMode.phase)
    return (
        <div className="p-2">
            {switchPhase(examModePhase)}
        </div>
    )
}