//BackBtn
import Button from 'react-bootstrap/Button'

export function ButtonTypeTwo({ text, action, disabled=false }) {
    return (
        <div className="btn-container">
            <Button className="action-btn" variant="secondary" disabled={disabled} onClick={() => action()}>
                {text}
            </Button>
        </div>
    )
    
}
