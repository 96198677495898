import "./RadioNavigate.css";
export default function RadioNavigate({ qnsList, currentQns, action }) {
  return (
    <>
      {qnsList.map((qns, idx) => {
        return (
          <button
            className={`radio-btn-span ${
              currentQns.includes(idx) && !qns.isAnswered
                ? "current-question"
                : ""
            } ${qns.isAnswered ? "question-answered" : ""}`}
            key={qns.id}
            onClick={() => action(idx)}
          >
            {qns.id}
          </button>
        );
      })}
    </>
  );
}
