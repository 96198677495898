//NextBtn and SubmitBtn
import Button from "react-bootstrap/Button";

export function ButtonTypeThree({
  text,
  type = "button",
  action = () => {},
  disabled = false,
}) {
  return (
    <div className="btn-container">
      <Button
        type={type}
        className="action-btn"
        style={{
          width: "100%",
        }}
        variant="dark"
        onClick={action}
        disabled={disabled}
      >
        {text}
      </Button>
    </div>
  );
}
