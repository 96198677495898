import { createSlice } from "@reduxjs/toolkit"
import { submitContactResponse } from "../thunks/fetchDataThunk"

const initialState = {
    submitSuccess: false
}

const contactAppSlice = createSlice({
    name: "contactApp",
    initialState,
    reducers: {
        setSubmitSuccess: (state) => {
            state.submitSuccess = true
        },
        resetSubmitStatus: (state) => {
            state.submitSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitContactResponse.fulfilled, (state) => {
                state.submitSuccess = true
            })
    }
})


export const { setSubmitSuccess, resetSubmitStatus } = contactAppSlice.actions
export default contactAppSlice.reducer