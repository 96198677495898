import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';
import { useFormContext } from 'react-hook-form';
import "./TopicsDropdown.css"

import { useDispatch, useSelector } from 'react-redux';

export default function TopicsDropdown({ onSelect, selected }) {

    const dispatch = useDispatch()
    const form = useFormContext()

    const topicObject = useSelector((state) => state.testApp.configs.activeTopics)

    const getTopicsOptionsArr = (topicObj) => {
        let arr = []
        for (let k in topicObj) {
            arr.push({ value: k, label: topicObj[k] })
        }
        return arr
    }

    const handleChange = (e) => {
        dispatch(onSelect(e.value))
        form.setValue("topic", e.value)
        form.trigger("topic")
    }

    return (
        <>
            <h6>Topics</h6>
            {
                topicObject ?
                    <div className="topic-selection-div">
                        <Dropdown
                            className={""}
                            {
                            ...form.register("topic", {
                                required: {
                                    value: true,
                                    message: "Please select a topic"
                                }
                            })
                            }
                            options={getTopicsOptionsArr(topicObject)}
                            onChange={handleChange}
                            value={selected ? selected : ""}
                            placeholder="Select an option"
                        />
                    </div >
                    :
                    "Error loading topics"
            }
            {
                form.formState.errors?.topic && (
                    <span className="text-danger">
                        {form.formState.errors?.topic.message}
                    </span>
                )
            }
        </>
    )
}