import { QUESTION_TYPE } from "../constants"
import QuestionTypeOne from "../components/questions/QuestionTypeOne"
import QuestionTypeOneGraded from "../components/questions/QuestionTypeOneGraded"
import QuestionTypeTwo from "../components/questions/QuestionTypeTwo"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

export function gradeTest(test, testResponse, testAnswersAndExplanation) {
    // check what kind of questions and grade accordingly
    let count = 0
    for (let i = 0; i < test.length; i++) {
        const question = test[i]
        const qid = question.qid
        const userResponse = testResponse[qid]
        const correctAnswer = testAnswersAndExplanation.answers[qid]
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            const result = gradeQuestionTypeOne(userResponse, correctAnswer)
            if (result) count++
            question['grade'] = result
            question['answer'] = testAnswersAndExplanation.answers[qid][0]
            question['explanation'] = testAnswersAndExplanation.explanations[qid]
        } else if (question.qnTyp === QUESTION_TYPE.MULTI) {
            // grade question type two
            const result = gradeQuestionTypeTwo(userResponse, correctAnswer)
            if (result) count++
            question['grade'] = result
        }
    }
    return { gradedQuestions: test, count }
}



export const renderQuestions = (questions) => {
    return questions.map((question, idx) => {
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            return <QuestionTypeOne key={idx} qns={question} idx={idx} />
        } else if (question.qnTyp === QUESTION_TYPE.MULTI) {
            return <QuestionTypeTwo key={idx} qns={question} idx={idx} />
        }
        return null
    })
}

// responses is an object containing all the responses
// key = qid, value = response
export const renderQuestionsComponent = (questions, responses, action) => {
    return questions.map((question, idx) => {
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            return <QuestionTypeOne key={idx} qns={question} resp={responses[question.qid]} action={action} />
        } else if (question.qnTyp === QUESTION_TYPE.MULTI) {
            return <QuestionTypeTwo key={idx} qns={question} resp={responses[question.qid]} action={action} />
        }
        return null
    })
}

export const renderGradedQuestions = (questions) => {
    return questions.map((question, idx) => {
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            return <QuestionTypeOneGraded key={idx} qns={question} idx={idx} />
        }
        return null
    })
}


export const renderGradedQuestionsComponent = (questions, responses) => {
    return questions.map((question, idx) => {
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            return <QuestionTypeOneGraded key={idx} qns={question} resp={responses[question.qid]} idx={idx} />
        }
        return null
    })
}

function gradeQuestionTypeOne(response, answer) {
    if (response == undefined) {
        return false
    }

    if (response === answer[0]) return true
    else return false
}

function gradeQuestionTypeTwo(response, answer) {

    if (response == undefined) {
        return false
    }

    if (response.length !== answer.length) {
        return false
    }

    const sortedResponse = response.slice().sort()
    const sortedAnswer = answer.slice().sort()

    for (let i = 0; i < answer.length; i++) {
        if (sortedResponse[i] !== sortedAnswer[i]) {
            return false
        }
    }

    return true
}

export function useNavigateBack() {
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1)
    }
    return navigateBack;
}

export function useNavigateHome() {
    const navigate = useNavigate();
    return () => navigate('/user');
}

export function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }
        // Bind
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // dispose
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

export function getRandomTrainingQuote (quote) {
    const quoteIdx = Math.floor(Math.random() * quote.length);
    return quote[quoteIdx];
}

export function sortCardByCode (a, b) {
    if (a.code < b.code)
        return -1
    else 
        return 1
}

export function sortSurveyByQid (a, b) {
    if (a.qid < b.qid)
        return -1
    else 
        return 1
}

export function checkSurveyByCode (completedSurveys, code) {
    if (completedSurveys.includes(code)){
        return true
    } else {
        return false
    }
}