import { createSlice } from "@reduxjs/toolkit";
import { getConfigsForApp } from "../thunks/fetchDataThunk";

const initialState = {
    error: false,
    errorMsg: "",
    configs: {},
    crashStatus: false
}

const testAppSlice = createSlice({
    name: "testApp",
    initialState,
    reducers: {
        setTestMode: (state, action) => {
            state.mode = action.payload
        },
        setConfigs: (state, action) => {
            // where used?
            state = action.payload
        },
        resetState: () => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfigsForApp.pending, (state) => {
                console.log("Getting configs")
            })
            .addCase(getConfigsForApp.fulfilled, (state, action) => {
                if (action.payload) {
                    state.configs = action.payload.payload
                    state.error = false
                }
            })
            .addCase(getConfigsForApp.rejected, (state) => {
                state.error = true
                console.log("Error getting configs")
            })

    }
})

export const { setTestMode, setConfigs } = testAppSlice.actions
export default testAppSlice.reducer