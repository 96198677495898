import { useDispatch, useSelector } from "react-redux"
import { renderGradedQuestions, renderGradedQuestionsComponent, useNavigateHome } from "../../../services/utilityFunctions";
import { resetState, restartTest } from "../../../redux/slice/practiceModeSlice";
import { ButtonTypeOne } from "../../../components/buttons/ButtonTypeOne";

export default function PracticeModeEnd() {
    const dispatch = useDispatch();
    const navigateHome = useNavigateHome();
    const questions = useSelector((state) => state.practiceMode.questions);
    const responses = useSelector((state) => state.practiceMode.testResponse)
    const numOfCorrectQns = useSelector((state) => state.practiceMode.numberOfCorrectQuestions)
    const topicList = useSelector((state) => state.testApp.configs.activeTopics)
    const currentTopic = useSelector((state) => state.practiceMode.topic)


    const restartTestHandler = () => {
        dispatch(restartTest())
    }

    const returnHomeHandler = () => {
        dispatch(resetState())
        navigateHome()
    }

    return (
        <div id="pm-end-main-div">
            <div id="pm-end-title-div" className="mb-3">
                <h1 className="page-title">Practice Mode Completed 🎉</h1>
            </div>
            <div id="pm-end-topic-div" className="mb-2">
                <span className="topic-title">Topic: {topicList[currentTopic]}</span>
            </div>
            <div id="pm-end-topic-score-div" className="mb-2">
                <span className="topic-title">Score:
                    🎯 {numOfCorrectQns} / {questions.length}
                </span>
            </div>
            <div id="pm-end-radionav-div" className="mb-2">
                {/* Component here */}
            </div>
            <div id="pm-graded-qns-div">
                {
                    renderGradedQuestionsComponent(questions, responses)
                }
            </div>
            <div className="row flex-column flex-column-reverse flex-md-row justify-content-center">
                <div className="col-12 col-md-3 d-flex justify-content-center">
                    <ButtonTypeOne text={"RESTART TEST"} action={restartTestHandler} />
                </div>
                <div className="col-12 col-md-3 d-flex justify-content-center">
                    <ButtonTypeOne text={"RETURN HOME"} action={returnHomeHandler} />
                </div>
            </div>
        </div>
    )
}