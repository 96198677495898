import axios from "axios";
import { getAccessToken } from "../../services/firebase";
import { BASE_URL } from "../../constants";

const setupAxiosInterceptors = (onUnauthenticated) => {
    const onRequestSuccess = async (config) => {
        const accessToken = await getAccessToken();
        console.log("access token: ", accessToken);

        config.baseURL = BASE_URL;
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers["Content-Type"] = "application/json";

        return config;
    };

    const onRequestError = (error) => {
        return Promise.reject(error);
    };

    const onResponseSuccess = (response) => {
        return Promise.resolve(response);
    };

    const onResponseError = (error) => {
        console.log("Error: ", error);

        const status = error.response?.status;
        if (status === 401 || status === 403) {
            onUnauthenticated(status);
        }

        return Promise.reject(error);
    };

    // Setup axios interceptors
    const requestInterceptor = axios.interceptors.request.use(
        onRequestSuccess,
        onRequestError
    );
    const responseInterceptor = axios.interceptors.response.use(
        onResponseSuccess,
        onResponseError
    );

    return {
        requestInterceptor,
        responseInterceptor,
    };
};

export default setupAxiosInterceptors;
