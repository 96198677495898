import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    phase: 0,
    topic: "",
    numOfQns: "",
}


const quickModeSlice = createSlice({
    name: "quickMode",
    initialState,
    reducers: {
        setPhase: (state, action) => {
            state.phase = action.payload
        },
        setTopic: (state, action) => {
            state.topic = action.payload
        },
        setNumOfQns: (state, action) => {
            state.numOfQns = action.payload
        },
        resetForm: () => initialState

    }
})

export const { setTopic, setNumOfQns } = quickModeSlice.actions
export default quickModeSlice.reducer