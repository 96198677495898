import { useSelector } from "react-redux";
import ProfileDetails from "./profile-details/ProfileDetails";
import "./ProfilePage.css"

export default function ProfilePage() {
    // get state for user 
    // - if first sign in/not yet fill in profile -> render the first sign in app
    // - else render normal profile page

    const userProfile = useSelector((state) => state.auth.profile)

    return (
        <div id="profile-page" className="p-2">
            <ProfileDetails userDetails={userProfile} />
        </div>
    )
}