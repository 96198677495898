import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slice/rootSlice";
import notificationMiddleware from "./middleware/notification";

export const store = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: {
    //             ignoredActions: ["auth/setUser"]
    //         }
    //     })
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActionPaths: ['payload']
        }
    }).concat(notificationMiddleware)
})