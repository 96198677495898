export default function Timer({ time }) {

    const minutes = Math.floor(time/60) //Rounds down to the nearest integer
    const seconds = time % 60 //Calculates remaining seconds after converting to minutes

    return (
        <>
        <h6> ⏰ {minutes > 0 ? `${minutes} min`: ''} {seconds} s </h6>              
        </>
    )
}