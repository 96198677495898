import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswerByQID } from '../../redux/slice/testAppSlice';
export default function QuestionTypeTwo({ qns, resp, action }) {
    const dispatch = useDispatch();
    const options = qns.options.map((opt, idx) => <Form.Check key={idx} type='checkbox' name={qns.qid} label={opt} value={opt} checked={resp && resp.includes(opt) ? true : ''} onChange={() => handleCheckbox(opt)} />)
    const checkboxResponses = resp
    const handleCheckbox = (value) => {
        if (!checkboxResponses || checkboxResponses.length === 0) {
            dispatch(action({ qid: qns.qid, value: [value] }));
        } else {
            // Check if the value is already in the array, if yes, remove it; otherwise, add it
            const updatedResponses = checkboxResponses.includes(value)
                ? checkboxResponses.filter((v) => v !== value)
                : [...checkboxResponses, value];

            dispatch(action({ qid: qns.qid, value: updatedResponses }));
        }
    }
    return (
        <div className="qns-div">
            {/* {idx + 1 + ". "} */}
            {
                qns.prompt
            }
            <br />
            <Form>
                {
                    options
                }
            </Form>
        </div>
    )
}