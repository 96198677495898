import "./PracticeModeStart.css";
import { useDispatch, useSelector } from "react-redux";
import { setNumOfQns, setTopic } from "../../../redux/slice/practiceModeSlice";
import { getPracticeModeTest } from "../../../redux/thunks/fetchDataThunk";
import { useNavigateBack } from "../../../services/utilityFunctions";
import { FormProvider, useForm } from "react-hook-form";
import TopicsDropdown from "../../../components/dropdown/TopicsDropdown";
import NumberDropdown from "../../../components/dropdown/NumberDropdown";

export default function PracticeModeStart() {

  const dispatch = useDispatch();
  const practiceModeState = useSelector((state) => state.practiceMode)
  const testApp = useSelector((state) => state.testApp)
  const uid = useSelector((state) => state.auth.profile.uid)
  const { isLoading } = useSelector((state) => state.loadingState)
  const form = useForm({
    defaultValues: {
      topic: practiceModeState.topic,
      numOfQns: practiceModeState.numOfQns,
    },
    mode: "all"
  })

  const getTest = () => {
    // frontend validation before calling
    dispatch(
      getPracticeModeTest({
        topic: practiceModeState.topic,
        uid,
        numOfQns: practiceModeState.numOfQns,
      })
    );
  };

  const navigateBack = useNavigateBack();

  const onSubmit = async (data) => {
    console.log("form data: ", data);
    getTest();
  };

  const onError = (error) => {
    console.error("form error: ", error);
  };

  return (
    <div id="pm-start-main-div">
      <div id="pm-start-title-div">
        <h3 className="page-title">Practice Mode ✍️</h3>
      </div>
      <p className="page-description">
        Pick a topic and the number of questions, and get ready for a quiz
        showdown!
      </p>
      {!testApp.error ? (
        <FormProvider {...form}>
          <form noValidate onSubmit={form.handleSubmit(onSubmit, onError)}>
            <div className="row">
              <div className="col-sm-2 col-lg-4"></div>
              <div className="col-sm-8 col-lg-4">
                <div id="pm-start-topic-selection-div" className="mb-2">
                  <TopicsDropdown
                    onSelect={setTopic}
                    selected={practiceModeState.topic}
                  />
                </div>
                <div id="pm-start-qns-selection-div" className="mb-2">
                  <NumberDropdown
                    onSelect={setNumOfQns}
                    selected={practiceModeState.numOfQns}
                  />
                </div>
                <div id="pm-start-submit-form-div">
                  <button
                    className="btn btn-pm-submit action-btn"
                    disabled={isLoading}
                  >
                    Get Started
                  </button>
                </div>
              </div>
              <div className="col-sm-2 col-lg-4"></div>
            </div>
          </form>
        </FormProvider>
      ) : (
        "Error for Practice Mode. Please try again."
      )}
    </div>
  );
}
