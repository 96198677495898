import './App.css';
import LandingPage from "./pages/landing-page/LandingPage"
import ErrorPage from "./pages/error-page/ErrorPage"
import UserPage from "./pages/user-page/UserPage"
import SignupPage from "./pages/signup-page/SignupPage"
import TestApp from './pages/test-app/TestApp';
import Navbar from "./components/navbar/Navbar"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { ProtectedRoutes } from "./services/ProtectedRoutes"
import { LoadingModal } from './components/modal/LoadingModal';
import React, { useEffect } from 'react';
import Notification from './components/notification/Notification';
import { useSelector } from 'react-redux';
import ProfilePage from './pages/profile-page/ProfilePage';
import LeaderboardPage from './pages/leaderboard-page/LeaderboardPage';
import ContactApp from './pages/contact-app/ContactApp';
import SurveyApp from './pages/survey-app/SurveyApp';
import setupAxiosInterceptors from './redux/middleware/axiosInterceptors';
import { signOut } from './services/firebase';


function App() {
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const onUnauthenticated = (statusCode) => {
      console.log("onUnauthenticated");
      navigate("/error", {
        state: {
          status: statusCode
        }
      });
      // signOut();
    }
    
    setupAxiosInterceptors(onUnauthenticated);
  }, []);


  return (
    <div id="app-main">
      <LoadingModal />
      <Navbar />
      <div id="main" className="container">
        <Routes>
          {loggedIn && <Route path="/" element={<Navigate to="/user" />} />}
          <Route path="/" element={<LandingPage />} ></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/user" element={<UserPage />}></Route>
            <Route path="/test" element={<TestApp />}></Route>
            <Route path="/survey" element={<SurveyApp/>}></Route>
            <Route path="/profile" element={<ProfilePage />}></Route>
            <Route path="/leaderboard" element={<LeaderboardPage />}></Route>
            <Route path="/contact" element={<ContactApp />}></Route>
          </Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      </div>
      {/* <Footer /> */}
    </div>

  );
}

export default App;
