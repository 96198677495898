import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

export function ProtectedRoutes() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const isLoading = useSelector((state) => state.auth.isLoading)

    if (isLoading) {
        return "Loading"
    }

    if (isLoggedIn) {
        return <Outlet />
    }

    return <Navigate to="/signup" replace />
}