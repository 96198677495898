import "./Navbar.css"
import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import { auth } from '../../services/firebase';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Notification from "../notification/Notification";
import CBLogo from "../../images/logo.svg"
import { IoExitOutline } from "react-icons/io5";
import { IoPersonCircleOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineInsertChart } from "react-icons/md";
import { useRef, useState } from "react";
import { ButtonTypeThree } from "../buttons/ButtonTypeThree";
import { useClickOutside } from "../../services/utilityFunctions";
import { FiHome } from "react-icons/fi";
function NavBar() {

    const loggedIn = useSelector((state) => state.auth.isLoggedIn)
    const navigate = useNavigate()
    const [displayDropdown, setDisplayDropdown] = useState(false)
    const dropdown = useRef("dropdown")

    const logout = () => {
        auth.signOut().then(() => {
            console.log("Signed out successfully")
        })
            .catch((err) => {
                console.error("Error signing out: ", err)
            })
    }

    const toHomePage = () => {
        // dispatch(resetState())
        if (loggedIn) {
            navigate("/user")
        } else {
            navigate("/")
        }
    }

    useClickOutside(dropdown, () => {
        setDisplayDropdown(false)
    })

    return (
        <div id="nav-bar-div" className="sticky-top">
            <Navbar id="navbar">
                <Container>
                    <Navbar.Brand onClick={toHomePage}>
                        <img alt="capsule bank logo" src={CBLogo} width="50px" />
                        <span style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            marginLeft: "3px"
                        }}>capsulebank</span>
                    </Navbar.Brand>
                    <Nav className="ml-auto">
                        {
                            loggedIn ?
                                // <IoExitOutline style={{ fontSize: "25px" }} onClick={() => logout()} /> 
                                <div ref={dropdown} style={{
                                    position: "relative",
                                    padding: "10px",
                                    borderRadius: "10px 10px 0 0",
                                    zIndex: "1000",
                                    animation: displayDropdown ? "fade-color-in 0.3s ease-in forwards" : "fade-color-out 0.3s ease-out forwards",
                                }}>
                                    <RxHamburgerMenu style={{
                                        fontSize: "30px",
                                        cursor: "pointer",
                                        animation: displayDropdown ? "rotate-on 0.3s ease-in forwards" : "rotate-off 0.3s ease-out forwards"
                                    }}
                                        onClick={() => setDisplayDropdown(!displayDropdown)}
                                    />
                                    {displayDropdown ?
                                        <div id="nav-dropdown" style={{
                                            animation: displayDropdown ? "fade-in 0.3s ease-in forwards" : "fade-out 0.3s ease-out forwards"
                                        }}>
                                            <div className="p-1 d-flex justify-content-start">
                                                <div className="d-flex justify-content-center align-items-center" onClick={() => navigate("/user")} >
                                                    <FiHome style={{
                                                        margin: "0 2px",
                                                        fontSize: "18px"
                                                    }} />
                                                    <span style={{ fontSize: "15px" }}>Home</span>
                                                </div>
                                            </div>
                                            <hr className="nav-divider"></hr>
                                            <div className="p-1 d-flex justify-content-start">
                                                <div className="d-flex justify-content-center align-items-center" onClick={() => navigate("/profile")} >
                                                    <IoPersonCircleOutline style={{
                                                        margin: "0 2px",
                                                        fontSize: "18px"
                                                    }} />
                                                    <span style={{ fontSize: "15px" }}>Profile</span>
                                                </div>
                                            </div>
                                            <hr className="nav-divider"></hr>
                                            <div className="p-1 d-flex justify-content-start">
                                                <div className="d-flex justify-content-center align-items-center" onClick={() => navigate("/dashboard")} >
                                                    <MdOutlineInsertChart style={{
                                                        margin: "0 2px",
                                                        fontSize: "18px"
                                                    }} />
                                                    <span style={{ fontSize: "15px" }}>Dashboard</span>
                                                </div>
                                            </div>
                                            <hr className="nav-divider"></hr>
                                            <div className="p-1 d-flex justify-content-start">
                                                <div className="d-flex justify-content-center align-items-center" onClick={() => logout()}>
                                                    <IoExitOutline style={{
                                                        margin: "0 2px",
                                                        fontSize: "18px"
                                                    }} />
                                                    <span style={{ fontSize: "15px" }}>Logout</span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                <ButtonTypeThree text="Try for free" action={() => navigate("/signup")} />
                        }
                    </Nav>

                </Container>

            </Navbar>
            <Notification />
        </div >
    );
}

export default NavBar;