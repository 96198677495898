import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    phase: 0,
    topic: "",
    numOfQns: "",
    tsid: "",
    questions: [],
    questionsLoadStatus: "", // not used anywhere
    challengeResponses: {},
    numberOfCorrectQuestions: "",
    timerActive: false
}

const challengeModeSlice = createSlice({
    name: "challengeMode",
    initialState,
    reducers: {

    }
})

// export const { }
export default challengeModeSlice.reducer