import "./MemberStatus.css"
import { IoDiamondOutline } from "react-icons/io5";
export default function MemberStatus({ member }) {

    if (!member) {
        return (
            <div id="member-div" className="d-flex justify-content-center align-items-center">
                <h3 className="m-0 px-2 py-1">Member</h3>
            </div>
        )
    }


    if (member) {
        return (
            <div id="member-div" className="d-flex justify-content-center align-items-center premium-member">
                <h3 className="m-0 px-2 py-1"><IoDiamondOutline /> Premium</h3>
            </div>
        )
    }
}