import { createSlice } from "@reduxjs/toolkit"
import { getPracticeModeAnswer, getPracticeModeTest } from "../thunks/fetchDataThunk"
import { HTTP_STATUS } from "../../constants"
import { gradeTest } from "../../services/utilityFunctions"

const initialState = {
    phase: 0,
    topic: "",
    numOfQns: "",
    tsid: "",
    questions: [],
    questionsLoadStatus: "", // not used anywhere
    testResponse: {},
    numberOfCorrectQuestions: "",
    error: false
}

const practiceModeSlice = createSlice({
    name: "practiceMode",
    initialState,
    reducers: {
        setPhase: (state, action) => {
            state.phase = action.payload
        },
        setTopic: (state, action) => {
            state.topic = action.payload
        },
        setNumOfQns: (state, action) => {
            state.numOfQns = action.payload
        },
        restartTest: (state) => {
            state.phase = 0
            state.questions = []
            state.testResponse = {}
            state.numberOfCorrectQuestions = ""
        },
        resetState: () => {
            return initialState
        },
        setAnswerByQID: (state, action) => {
            state.testResponse[action.payload.qid] = action.payload.value
        },
        resetForm: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPracticeModeTest.pending, (state) => {
                state.questionsLoadStatus = HTTP_STATUS.PENDING
            })
            .addCase(getPracticeModeTest.fulfilled, (state, action) => {
                state.questionsLoadStatus = HTTP_STATUS.FULFILLED
                let response = action.payload.payload
                let testQuestions = response.testQuestions
                state.questions = testQuestions
                if (testQuestions.length > 0) {
                    state.tsid = response.tsid
                    state.phase = 1
                } else {
                    alert("No questions found")
                }
            })
        builder
            .addCase(getPracticeModeAnswer.pending, (state) => {
                console.log("getting answers for the questions")
            })
            .addCase(getPracticeModeAnswer.fulfilled, (state, action) => {
                console.log("answers received")
                // call the grading of questions here
                let response = action.payload.payload
                let { gradedQuestions, count } = gradeTest(state.questions, state.testResponse, response)
                state.questions = gradedQuestions
                state.numberOfCorrectQuestions = count
                state.phase = 2
                console.log("questions are graded successfully")
            })
    }
})

export const { setTopic, setNumOfQns, setAnswerByQID, resetState, restartTest } = practiceModeSlice.actions
export default practiceModeSlice.reducer