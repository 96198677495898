import "./ExamModeDo.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import RadioNavigate from "../../../components/buttons/RadioNavigate";
import { ButtonTypeTwo } from "../../../components/buttons/ButtonTypeTwo";
import { ButtonTypeThree } from "../../../components/buttons/ButtonTypeThree";
import QuestionPagination from "../../../components/QuestionPagination";
import Timer from "../../../components/timer/Timer";
import { setAnswerByQID } from "../../../redux/slice/examModeSlice";
import { submitExamModeAnswer } from "../../../redux/thunks/fetchDataThunk";
import { useNavigateHome } from "../../../services/utilityFunctions";
import { ButtonTypeOne } from "../../../components/buttons/ButtonTypeOne";
import { SubmitBtnWithConfirmation } from "../../../components/buttons/SubmitBtnWithConfirmation";

export default function ExamModeDo() {
  const examQuestions = useSelector((state) => state.examMode.questions);
  const examResponses = useSelector((state) => state.examMode.examResponses);
  const topicCode = useSelector((state) => state.examMode.topic);
  const topicList = useSelector((state) => state.testApp.configs.activeTopics);
  const { isLoading } = useSelector((state) => state.loadingState);
  const [currentQns, setCurrentQns] = useState([0]);
  const [step] = useState(1);
  const dispatch = useDispatch();

  const esid = useSelector((state) => state.examMode.esid);

  // cases scenario
  // Case: Step = 2, Click on 2
  // - display 1 and 2
  // Case: Step = 3, Click on 2
  // - display 1, 2, 3
  // Case: step = 3, click on 3
  // - display 1, 2, 3
  // Strategy
  // 1) Divide by step
  // 2) Find the block to display
  // E.g. 9 divide by 3 (step)
  // Total Blocks: 3 (B1, B2, B3)
  // B1: 0,1,2
  // B2: 3,4,5
  // B3: 6,7,8
  // Solution:
  // - calculate the pagination in arr of arr first [[0,1], [2,3]]
  // - keep variable for current page
  // - render which arr based on page
  // - Each radio btn to do pages instead. E.g. () 1-5 () 6-10 () 11-15

  // Timer
  const [time, setTime] = useState(70);
  const timerActive = useSelector((state) => state.examMode.timerActive);
  useEffect(() => {
    let interval = null;
    if (timerActive) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 0) {
            // dispatch to submit also
            clearInterval(interval);
            submitExamModeTest();
            return 0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval); // clear interval on unmount of component
  }, [timerActive]);

  const radioNavigateArr = examQuestions.map((question, index) => ({
    id: index + 1,
    isAnswered: Object.keys(examResponses).includes(question.qid),
  }));

  const navigateHome = useNavigateHome();

  const handleForwardBtn = () => {
    if (currentQns === examQuestions.length) {
      // submit
    } else {
      // check how many steps -> handle multiple
      // assume one step first
      const newQns = currentQns.map((page) => page + step);
      console.log("newQns: ", newQns);
      setCurrentQns(newQns);
    }
  };

  const handleBackwardBtn = () => {
    if (currentQns === 0) {
      // cannot move back
    } else {
      const newQns = currentQns.map((page) => page - step);
      setCurrentQns(newQns);
    }
  };

  const handleRadioBtn = (question) => {
    const value = parseInt(question);
    const newQns = currentQns.map(() => value);
    setCurrentQns(newQns);
  };

  const renderForwardButton = () => {
    if (currentQns[0] === examQuestions.length - 1) {
      return <SubmitBtnWithConfirmation action={submitExamModeTest} disabled={isLoading} />;
    } else {
      return <ButtonTypeThree text={"NEXT"} action={handleForwardBtn} />;
    }
  };

  const renderBackwardButton = () => {
    if (currentQns[0] === 0) {
      return <ButtonTypeTwo text={"PREVIOUS"} disabled={true} />;
    } else {
      return <ButtonTypeTwo text={"PREVIOUS"} action={handleBackwardBtn} />;
    }
  };

  const submitExamModeTest = () => {
    dispatch(submitExamModeAnswer({ esid, examResponses }));
  };

  return (
    <div id="em-do-main-div">
      <div id="em-do-title-div" className="mb-3">
        <h3 className="page-title">Exam Mode</h3>
      </div>
      <Timer time={time} />
      <div id="em-do-topic-div" className="mb-2">
        <span className="topic-title">Topic: {topicList[topicCode]}</span>
      </div>
      <div id="em-do-radionav-div" className="d-flex gap-2 py-3">
        <RadioNavigate
          qnsList={radioNavigateArr}
          currentQns={currentQns}
          action={handleRadioBtn}
        />
      </div>
      <div id="em-do-qns-display-div" className="mb-3">
        <p className="mb-2 question-number">
          Question {parseInt(currentQns) + 1}
        </p>
        <QuestionPagination
          testQuestions={examQuestions}
          currentQns={currentQns}
          testResponses={examResponses}
          action={setAnswerByQID}
        />
      </div>
      <div
        id="em-do-btn-div"
        className="d-flex justify-content-between gap-2 flex-wrap"
      >
        <ButtonTypeOne
          text="RETURN HOME"
          style={{ width: "100%" }}
          action={navigateHome}
        />
        <div className="d-flex gap-2">
          {renderBackwardButton()}
          {renderForwardButton()}
        </div>
      </div>
    </div>
  );
}
