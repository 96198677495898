import "./ExamModeStart.css"
import { useDispatch, useSelector } from "react-redux";
import { setTopic } from "../../../redux/slice/examModeSlice";
import { getExamModeTest } from "../../../redux/thunks/fetchDataThunk";
import { FormProvider, useForm } from "react-hook-form";
import TopicsDropdown from "../../../components/dropdown/TopicsDropdown";
import { reduceCreditCount } from "../../../redux/slice/authSlice";

export default function ExamModeStart() {
    const dispatch = useDispatch();
    const examModeState = useSelector((state) => state.examMode);
    const testApp = useSelector((state) => state.testApp)
    const uid = useSelector((state) => state.auth.profile.uid)
    const creditCount = useSelector((state) => state.auth.profile.credit)
    const { isLoading } = useSelector((state) => state.loadingState)
    const form = useForm({
        defaultValues: {
            topic: examModeState.topic,
        },
        mode: "all"
    })

    const getExam = () => {
        dispatch(getExamModeTest({ topic: examModeState.topic, uid }))
        if (creditCount > 0) {
            dispatch(reduceCreditCount())
        }
    }

    const onSubmit = async (data) => {
        console.log("form data: ", data)
        await getExam()
    }

    const onError = (error) => {
        console.error("form error: ", error)
    }

    return (
        <div id="em-start-main-div">
            <div id="em-start-title-div">
                <h3 className="page-title">Exam Mode 💯</h3>
            </div>
            <p className="page-description">
                Test your knowledge in a full fledged proctored test!
            </p>
            <p className="page-description">You have <b>1 hour</b> to complete <b>50 questions</b>. Exam Mode is only available 3 times everyday so make it count!</p>
            <p className="page-description">Tries left: {creditCount}</p>
            {!testApp.error ?
                <div>
                    <FormProvider {...form} >
                        <form noValidate onSubmit={form.handleSubmit(onSubmit, onError)}>
                            <div className="row">
                                <div className="col-sm-2 col-lg-4"></div>
                                <div className="col-sm-8 col-lg-4">
                                    <div id="em-start-topic-selection-div" className="mb-2">
                                        <TopicsDropdown onSelect={setTopic} selected={examModeState.topic} />
                                    </div>
                                    <div id="pm-start-submit-form-div">
                                        <button className="btn btn-em-submit action-btn" disabled={isLoading} >Get Started</button>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-lg-4"></div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                :
                "Error for Exam Mode. Please try again."
            }
        </div>
    )
}