import { createSlice } from "@reduxjs/toolkit";
import { HTTP_STATUS } from "../../constants"
import { submitExamModeAnswer, getExamModeTest } from "../thunks/fetchDataThunk";
import { gradeTest } from "../../services/utilityFunctions";


const initialState = {
    phase: 0,
    topic: "",
    numOfQns: "",
    tsid: "",
    questions: [],
    questionsLoadStatus: "", // not used anywhere
    examResponses: {},
    numberOfCorrectQuestions: "",
    timerActive: false
}

const examModeSlice = createSlice({
    name: "examMode",
    initialState,
    reducers: {
        setPhase: (state, action) => {
            state.phase = action.payload
        },
        setTopic: (state, action) => {
            state.topic = action.payload
        },
        setAnswerByQID: (state, action) => {
            state.examResponses[action.payload.qid] = action.payload.value
        },
        // setNumOfQns: (state, action) => {
        //     state.numOfQns = action.payload
        // },
        restartTest: (state) => {
            state.phase = 0
            state.questions = []
            state.testResponse = {}
            state.numberOfCorrectQuestions = ""
        },
        resetState: () => {
            return initialState
        },
        resetForm: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getExamModeTest.fulfilled, (state, action) => {
                state.questionsLoadStatus = HTTP_STATUS.FULFILLED
                let response = action.payload.payload
                let examQuestions = response.examQuestions
                state.questions = examQuestions
                state.esid = response.esid
                state.phase = 1
                state.timerActive = true
            })
        builder
            .addCase(submitExamModeAnswer.fulfilled, (state, action) => {
                // let response = action.payload.payload
                // let { gradedQuestions, count } = gradeTest(state.questions, state.examResponses, response.answers)
                // state.questions = gradedQuestions
                // state.numberOfCorrectQuestions = count
                // state.phase = 2
                let response = action.payload.payload
                state.numberOfCorrectQuestions = response
                state.phase = 2
            })
    }
})


export const { setTopic, setNumOfQns, setAnswerByQID, resetState, restartTest } = examModeSlice.actions
export default examModeSlice.reducer