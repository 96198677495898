export const BASE_URL = "https://cb-backend-steelix-905a72bc5b97.herokuapp.com/api/v1"
// export const BASE_URL = "http://localhost:4040/api/v1"

export const HTTP_STATUS = Object.freeze({
    PENDING: 'PENDING',
    FULFILLED: 'FULFILLED',
    REJECTED: 'REJECTED'
})


export const QUESTION_TYPE = Object.freeze({
    MCQ: 'MCQ',
    MULTI: 'MULTI'
})


// Sample Data
export const sampleTopics = ['Cholinergic', 'Anticholinergic', 'Adrenergic']

export const sampleQuestions = [
    {
        qid: '1',
        qnTyp: 'MCQ',
        prompt: 'Irreversible COX inhibitor',
        options: ['Aspirin', 'Naproxen', 'Diclofenac', 'Celecoxib']
    },
    {
        qid: '2',
        qnTyp: 'MCQ',
        prompt: 'NSAIDs are useful as analgesic for: ',
        options: ['Moderate to severe pain', 'Neuropathic pain', 'Severe pain', 'Mild to moderate pain']
    },
    {
        qid: '3',
        qnTyp: 'MCQ',
        prompt: 'An elderly patient presents with bruising after taking an analgesic. The drug was most likely',
        options: ['Aspirin', 'Naproxen', 'Codeine', 'Ibuprofen']
    }
]

export const sampleQuestionsAnswers = [
    {
        qid: '1',
        answer: 'Aspirin'
    },
    {
        qid: '2',
        answer: 'Mild to moderate pain'
    },
    {
        qid: '3',
        answer: 'Aspirin'
    }
]