import { createSlice, isRejected } from "@reduxjs/toolkit"

const initialState = {
    display: false,
    type: "",
    msg: ""
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        notifySuccess: (state, action) => {
            state.display = true
            state.type = "success"
            state.msg = action.payload
        },
        notifyError: (state, action) => {
            state.display = true
            state.type = "error"
            state.msg = action.payload
        },
        notifyWarning: (state, action) => {
            state.display = true
            state.type = "warning"
            state.msg = action.payload
        },
        notifyClose: (state) => {
            state.display = false
        },
        notifyResetMessage: (state) => {
            state.msg = ""
            state.type = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isRejected, (state, action) => {
                state.display = true
                state.type = "error"
                state.msg = action.payload?.error?.message || action.payload || action.error.message
            })
    }
})


export const { notifySuccess, notifyError, notifyWarning, notifyClose, notifyResetMessage } = notificationSlice.actions
export default notificationSlice.reducer