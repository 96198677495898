import "./PracticeModeDo.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionPagination from "../../../components/QuestionPagination";
import { ButtonTypeTwo } from "../../../components/buttons/ButtonTypeTwo";
import { ButtonTypeThree } from "../../../components/buttons/ButtonTypeThree";
import RadioNavigate from "../../../components/buttons/RadioNavigate";
import { setAnswerByQID } from "../../../redux/slice/practiceModeSlice";
import { getPracticeModeAnswer } from "../../../redux/thunks/fetchDataThunk";
import { ButtonTypeOne } from "../../../components/buttons/ButtonTypeOne";
import { useNavigateHome } from "../../../services/utilityFunctions";
import { SubmitBtnWithConfirmation } from "../../../components/buttons/SubmitBtnWithConfirmation";

export default function PracticeModeDo() {
  const testQuestions = useSelector((state) => state.practiceMode.questions);
  const testResponses = useSelector((state) => state.practiceMode.testResponse);
  const topicCode = useSelector((state) => state.practiceMode.topic);
  const topicList = useSelector((state) => state.testApp.configs.activeTopics);
  const { isLoading } = useSelector((state) => state.loadingState);
  const [currentQns, setCurrentQns] = useState([0]); // this is set to array to allow for pagination further down
  const [step] = useState(1);
  const dispatch = useDispatch();

  const radioNavigateArr = testQuestions.map((question, index) => ({
    id: index + 1,
    isAnswered: Object.keys(testResponses).includes(question.qid),
  }));

  const navigateHome = useNavigateHome();

  const handleForwardBtn = () => {
    if (currentQns[0] === testQuestions.length) {
      // submit
    } else {
      // check how many steps -> handle multiple
      // assume one step first
      const newQns = currentQns.map((page) => page + step);
      setCurrentQns(newQns);
    }
  };

  const handleBackwardBtn = () => {
    if (currentQns === 0) {
      // cannot move back
    } else {
      const newQns = currentQns.map((page) => page - step);
      setCurrentQns(newQns);
    }
  };

  const handleRadioBtn = (question) => {
    const value = parseInt(question);
    const newQns = currentQns.map(() => value);
    setCurrentQns(newQns);
  };

  const renderForwardButton = () => {
    if (currentQns[0] === testQuestions.length - 1) {
      return <SubmitBtnWithConfirmation action={submitPracticeModeTest} disabled={isLoading}/>;
    } else {
      return <ButtonTypeThree text={"NEXT"} action={handleForwardBtn} />;
    }
  };

  const renderBackwardButton = () => {
    if (currentQns[0] === 0) {
      return <ButtonTypeTwo text={"PREVIOUS"} disabled={true} />;
    } else {
      return <ButtonTypeTwo text={"PREVIOUS"} action={handleBackwardBtn} />;
    }
  };

  const tsid = useSelector((state) => state.practiceMode.tsid);

  const submitPracticeModeTest = () => {
    console.log("submitPracticeModeTest called");
    dispatch(getPracticeModeAnswer({ tsid }));
  };
  

  return (
    <div id="pm-do-main-div">
      <div id="pm-do-title-div" className="mb-3">
        <h3 className="page-title">Practice Mode ✍️</h3>
      </div>
      <div id="pm-do-topic-div">
        <span className="topic-title">Topic: {topicList[topicCode]} </span>
      </div>
      <div id="pm-do-radionav-div" className="d-flex gap-2 py-3">
        <RadioNavigate
          qnsList={radioNavigateArr}
          currentQns={currentQns}
          action={handleRadioBtn}
        />
      </div>
      <div id="pm-do-qns-display-div" className="mb-3">
        <p className="mb-2 question-number">
          Question {parseInt(currentQns) + 1}
        </p>
        <QuestionPagination
          testQuestions={testQuestions}
          currentQns={currentQns}
          testResponses={testResponses}
          action={setAnswerByQID}
        />
      </div>
      <div id="pm-do-btn-div" className="d-flex justify-content-between gap-2 flex-wrap ">
        <ButtonTypeOne
          text="RETURN HOME"
          style={{ width: "100%" }}
          action={navigateHome}
        />
        <div className="d-flex gap-2">
          {renderBackwardButton()}
          {renderForwardButton()}
        </div>
      </div>
    </div>
  );
}
