import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';
import { useFormContext } from 'react-hook-form';
import "./NumberDropdown.css"
import { useDispatch } from 'react-redux';



export default function NumberDropdown({ onSelect, selected }) {

    const dispatch = useDispatch()
    const form = useFormContext()

    const handleChange = (e) => {
        dispatch(onSelect(e.value))
        form.setValue("numOfQns", e.value)
        form.trigger("numOfQns")
    }

    const options = [
        {
            label: 10,
            value: "1"
        },
        {
            label: 20,
            value: "2"
        }
    ]


    return (
        <>
            <h6>Number of Questions</h6>
            <Dropdown
                {...form.register("numOfQns", {
                    required: {
                        value: true,
                        message: "Please select number of questions",
                    },
                })}
                options={options}
                onChange={handleChange}
                value={selected ? selected : ""}
                placeholder="Select an option"
            />
            {
                form.formState.errors?.numOfQns && (
                    <span className="text-danger">
                        {form.formState.errors?.numOfQns.message}
                    </span>
                )
            }
        </>
    )
}