import { useDispatch, useSelector } from "react-redux"
//import { renderGradedQuestions, renderGradedQuestionsComponent } from "../../../services/utilityFunctions";
import { resetState, restartTest } from "../../../redux/slice/examModeSlice";
import { ButtonTypeOne } from "../../../components/buttons/ButtonTypeOne";
import { useNavigateHome } from "../../../services/utilityFunctions";

export default function ExamModeEnd() {

    const dispatch = useDispatch();
    const navigateHome = useNavigateHome();
    const questions = useSelector((state) => state.examMode.questions)
    //const responses = useSelector((state) => state.examMode.testResponse)
    const numOfCorrectQns = useSelector((state) => state.examMode.numberOfCorrectQuestions)
    const topicList = useSelector((state) => state.testApp.configs.activeTopics)
    const currentTopic = useSelector((state) => state.examMode.topic)

    const restartTestHandler = () => {
        dispatch(restartTest())
    }

    const returnHomeHandler = () => {
        dispatch(resetState())
        navigateHome()
    }

    return (
        <div id="em-end-main-div">
            <div id="pm-end-title-div" className="mb-3">
                <h3 className="page-title">Exam Mode Completed 🎉</h3>
            </div>
            <div id="pm-end-topic-div" className="mb-2">
                <span className="topic-title">Topic: {topicList[currentTopic]}</span>
            </div>
            <div id="pm-end-result-div" className="mb-2">
                <span className="topic-title">Score: 🎯 {numOfCorrectQns} / {questions.length}</span>
            </div>
            <div id="pm-end-radionav-div" className="mb-2">
                {/* Component here */}
            </div>
            <div id="pm-graded-qns-div">
                {
                    //renderGradedQuestionsComponent(questions, responses)
                }
            </div>
            <div className="row flex-column flex-column-reverse flex-md-row justify-content-center">
                <div className="col-12 col-md-3 d-flex justify-content-center">
                    <ButtonTypeOne text={"RESTART TEST"} action={restartTestHandler} />
                </div>
                <div className="col-12 col-md-3 d-flex justify-content-center">
                    <ButtonTypeOne text={"RETURN HOME"} action={returnHomeHandler} />
                </div>
            </div>
        </div>



    )
}