import { useNavigate } from "react-router-dom";
import { ButtonTypeThree } from "../../components/buttons/ButtonTypeThree";
import "./SectionTwo.css";

export default function SectionTwo() {
  const navigate = useNavigate()
  const infoCards = {
    first: {
      title: "Comprehensive Question Bank",
      paragraph: {
        first:
          "We cover a range of important medical topics, ensuring that we provide you with the best resources for your learning and practice needs.",
      },
    },
    second: {
      title: "Enhanced Learning",
      paragraph: {
        first:
          "You will get instant access to explanations after submission of answers, allowing you to immediately reinforce your understanding of concepts.",
      },
    },
    third: {
      title: "Accessibility and Convenience",
      paragraph: {
        first:
          "Enjoy the flexibility of accessing questions and explanations anytime, anywhere, ensuring seamless learning integration into busy schedules.",
      },
    },
  };

  return (
    <div id="section-two" className="container-fluid gx-0">
      <div className="row gx-0 mt-2 d-md-none">
        <ButtonTypeThree text="Get started &gt;" action={() => navigate("/user")} />
      </div>
      <div className="row g-2">
        {Object.keys(infoCards).map((key) => {
          return (
            <div key={key} className="col-12 col-md-4">
              <div className="info-card h-100">
                <div className="info-card-title-div">
                  <span className="card-title">{infoCards[key].title}</span>
                </div>
                <div className="info-card-title-description">
                  <p className="card-description">{infoCards[key].paragraph.first}</p>
                  {infoCards[key].paragraph.second ? (
                    <>
                      <br />
                      <p className="card-description">{infoCards[key].paragraph.second}</p>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
