import './QuestionTypeOneGraded.css'
import { Form } from 'react-bootstrap';
import ReportBtn from '../buttons/ReportBtn';
export default function QuestionTypeOneGraded({ qns, resp, idx }) {

    // const options = qns.options.map((opt, idx) => {
    //     const checked = resp === opt
    //     const isCorrect = qns.grade
    //     let optStyle;
    //     if (checked) {
    //         optStyle = isCorrect ? 'ans-success' : 'ans-fail';
    //     }
    //     return <Form.Check key={idx} type='radio' name={qns.qid} label={opt} checked={checked} className={optStyle} readOnly />
    // })

    const options = qns.options.map((opt, idx) => {
        const userChoice = resp === opt
        if (userChoice) {
            // User answered the question
            const isCorrect = resp === qns.answer
            const optStyle = isCorrect ? 'ans-success' : 'ans-fail';
            return <Form.Check key={idx} type='radio' label={opt} checked={true} className={optStyle} readOnly />
        } else {
            // User did not answer the qns
            const isCorrect = opt === qns.answer
            if (isCorrect) {
                // Show correct answer
                const optStyle = 'ans-success'
                return <Form.Check key={idx} type='radio' label={opt} checked={true} className={optStyle} readOnly />
            } else {
                return <Form.Check key={idx} type='radio' label={opt} checked={false} readOnly />
            }
        }
    })

    const renderCheckMark = () => {
        if (qns.grade) {
            return <>✅</>
        } else {
            return <>❌</>
        }
    }

    return (
        <div className="graded-qns-div mb-5">
            <div className="graded-qns-header d-flex justify-content-between mb-1">
                <div className="question-number">
                    <span>{renderCheckMark()}</span><h6 style={{ display: 'inline-block' }}>Question {idx + 1 + ": "}</h6>
                </div>
                <div>
                    <ReportBtn qid={qns.qid} />
                </div>
            </div>
            <div className="graded-qns-prompt mb-2">
                {
                    qns.prompt
                }
            </div>
            <div className="graded-qns-options mb-3">
                <Form>
                    {
                        options
                    }
                </Form>
            </div>
            <div className="graded-qns-exp mb-5">
                {
                    qns.explanation
                }
            </div>
            <hr className="qns-separator"></hr>
        </div>
    )
}