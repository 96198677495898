import { notifyClose } from "../slice/notificationSlice";

const notificationMiddleware = store => next => action => {

    if (action.payload?.error && action.type.endsWith("/fulfilled")) {
        // server sends back error
        setTimeout(() => {
            store.dispatch(notifyClose())
        }, 5000)
    } else if (action.type.endsWith("/rejected")) {
        // server down error
        setTimeout(() => {
            store.dispatch(notifyClose())
        }, 5000)
    }
    next(action)
}

export default notificationMiddleware