import "./UserPage.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigsForApp } from "../../redux/thunks/fetchDataThunk";
import { getRandomTrainingQuote, sortCardByCode, checkSurveyByCode } from "../../services/utilityFunctions";
import TitleSurveyCard from "../../components/card/TitleSurveyCard";
import TitleModeCard from "../../components/card/TitleModeCard";
import TitleContactCard from "../../components/card/TitleContactCard";


export default function UserPage() {
  const dispatch = useDispatch();
  const testApp = useSelector((state) => state.testApp);
  const userProfile = useSelector((state) => state.auth.profile);

  const sortedActiveModes = testApp.configs.activeModes?.toSorted(sortCardByCode)
  const sortedActiveSurveys = testApp.configs.activeSurveys?.toSorted(sortCardByCode)
  const activeQuotes = testApp.configs?.activeQuotes || []
  const completedSurvey = userProfile.completedSurveys || []

  useEffect(() => {
    if (Object.keys(testApp.configs).length == 0) {
      dispatch(getConfigsForApp());
    }
    if (testApp.error) {
      console.log("error call");
      dispatch(getConfigsForApp());
    }
  }, []);

  return (
    <div id="user-page" className="p-2">
      <h1 className="page-title">Hello, {userProfile.displayName} 👋</h1>
      <p id="user-page-greetings" className="page-description mb-3">
        What would you like to do today?
      </p>
      <hr id="user-page-title-divider" className="divider"></hr>

      {/* Can Refactor and list render the action cards here */}
      <div id="test-app" className="mb-5 user-page-section">
        <h3 className="section-title">Training Dojo 🥋</h3>
        <p id="test-app-description" className="section-description mb-2">
          {getRandomTrainingQuote(activeQuotes)}
        </p>
        {/* User bootstrap grid to stack on small screen and side-by-side on big */}
        <div className="row g-3">
          {sortedActiveModes?.map((data) => {
            return (
              <div key={data.code} className="d-flex col-lg-6">
                <TitleModeCard data={data} />
              </div>
            )
          })}
        </div>
        <hr className="divider"></hr>
      </div>

      {/* Can Refactor and list render the action cards here */}
      <div id="survey-app" className="mb-5 user-page-section">
        <h3 className="section-title">Feedback 🗒️</h3>
        <p id="survey-app-description" className="section-description mb-2">
          Help us complete the following surveys (and get rewarded) so we can
          improve. Your opinion matters to us!
        </p>
        <div className="row g-3">
          {sortedActiveSurveys?.map((data) => {
            const isSurveyCompleted = checkSurveyByCode(completedSurvey, data.code)
            return (
              <div key={data.code} className="d-flex col-lg-6">
                <TitleSurveyCard data={data} disabled={isSurveyCompleted} />
              </div>
            )
          })}
        </div>
        <hr className="divider"></hr>
      </div>

      <div id="contact-app" className="user-page-section">
        <h3 className="section-title">Contact Us ☎️</h3>
        <div className="row g-3">
          <div className="d-flex col-lg-6">
            <TitleContactCard />
          </div>
        </div>
      </div>
    </div>
  );
}
