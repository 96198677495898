
import BeforeUnloadModal from "../../components/modal/BeforeUnloadModal"
import "./TestApp.css"

// States
import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PracticeMode from "./practice-mode/PracticeMode"
import ExamMode from "./exam-mode/ExamMode"
import { getConfigsForApp } from "../../redux/thunks/fetchDataThunk";
import { setTestMode } from "../../redux/slice/testAppSlice"


const renderMode = (mode) => {
    if (mode == "M1") {
        return <PracticeMode />
    } else if (mode == "M2") {
        return <ExamMode />
    }
    return <Navigate to="/user" />
}

export default function TestApp() {
    const dispatch = useDispatch()
    const testApp = useSelector((state) => state.testApp)
    const mode = testApp.mode || sessionStorage.getItem("mode")
    console.log("current mode: ", mode)

    const handleOnBeforeUnload = () => {
        const mode = testApp.mode || sessionStorage.getItem("mode")
        console.log("beforeunload mode: ", mode)
        if (mode) {
            sessionStorage.setItem("mode", mode)
        }
    }

    useEffect(() => {
        if (Object.keys(testApp.configs).length == 0) {
            dispatch(getConfigsForApp())
        }
        if (testApp.error) {
            console.log("error call")
            dispatch(getConfigsForApp())
        }

        dispatch(setTestMode(mode))
    }, [])

    return (
        <div id="testApp">
            {/* {
                switchPhase(testAppState.phase)
            } */}
            <BeforeUnloadModal onBeforeUnload={handleOnBeforeUnload} />
            {renderMode(mode)}
        </div>
    )
}