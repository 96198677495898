import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    phase: 0,
    topic: "",
    numOfQns: "",
}


const timeModeSlice = createSlice({
    name: "timeMode",
    initialState,
    reducers: {
        setPhase: (state, action) => {
            state.phase = action.payload
        },
        setTopic: (state, action) => {
            state.topic = action.payload
        },
        setNumOfQns: (state, action) => {
            state.numOfQns = action.payload
        },
        resetForm: () => {
            return initialState
        }
    }
})

export const { setPhase, setTopic, setNumOfQns } = timeModeSlice.actions
export default timeModeSlice.reducer