import { useSelector } from "react-redux"
import PracticeModeDo from "./PracticeModeDo"
import PracticeModeEnd from "./PracticeModeEnd"
import PracticeModeStart from "./PracticeModeStart"


const switchPhase = (param) => {
    switch (param) {
        case 0:
            return <PracticeModeStart />
        case 1:
            return <PracticeModeDo />
        case 2:
            return <PracticeModeEnd />
    }
}

export default function PracticeMode() {
    const practiceModePhase = useSelector((state) => state.practiceMode.phase)
    return (
        <div className="p-2">
            {switchPhase(practiceModePhase)}
        </div>
    )
}