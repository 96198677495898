import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTestMode } from "../../redux/slice/testAppSlice";
import { resetState as resetPracticeModeState } from "../../redux/slice/practiceModeSlice";
import { resetState as resetExamModeState } from "../../redux/slice/examModeSlice";

export default function TitleModeCard({data, disabled=false}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const navigateTest = () => {
        dispatch(setTestMode(data.code))
        dispatch(resetPracticeModeState())
        dispatch(resetExamModeState())
        navigate("/test")
    }

    return (
        <button
            className={`action-card-div ${
            disabled ? "disable" : ""
            } d-flex flex-column gap-1`}
            onClick={navigateTest}
        >
            <h4 className="action-card-title card-title">{data.title}</h4>
            <p className="mt-1">{data.description}</p>
         </button>  
     )
}