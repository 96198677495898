import "./ProfileDetails.css"
import { IoPersonCircleOutline } from "react-icons/io5";

import MemberStatus from "../member-status/MemberStatus";
export default function ProfileDetails({ userDetails }) {

    return (
        <div id="profile-details" className="row p-3">
            <div className="col-md-12 mb-md-5">
                <h3 className="page-title text-center text-md-start">User Profile</h3>
            </div>
            <div id="profile-details-left" className="col-md-6">

                <div className="d-flex justify-content-center align-items-center">
                    {
                        userDetails.photoURL ? (
                            <img src={userDetails.photoURL} id="profile-icon-img" />
                        ) : (
                            <IoPersonCircleOutline id="profile-icon-img" />
                        )
                    }
                </div>
                <div id="membership-div" className="d-flex justify-content-center align-items-center w-100 mt-2 mb-2">
                    <MemberStatus member={userDetails.member} />
                </div>
            </div>
            <div id="profile-details-right" className="col-md-6">
                <div className="d-flex justify-content-center">
                    <div>
                        <h6 className="profile-field-title">Name</h6>
                        <p className="profile-field-text">{userDetails.displayName}</p>
                        <h6 className="profile-field-title">Email</h6>
                        <p className="profile-field-text">{userDetails.email}</p>
                        <h6 className="profile-field-title">Credit:</h6>
                        <p className="profile-field-text">{userDetails.credit}</p>
                    </div>
                </div>
            </div>


        </div >
    )
}