import "./ReportBtn.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { submitReportForQns } from "../../redux/thunks/fetchDataThunk";
import { ButtonTypeThree } from "./ButtonTypeThree";
import { ButtonTypeTwo } from "./ButtonTypeTwo";

export default function ReportBtn({ qid }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const uid = useSelector((state) => state.auth.profile.uid);
  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const form = useForm({
    defaultValues: {
      comment: comment,
    },
    mode: "all",
  });

  const handleSubmit = () => setIsSubmitted(true);

  const handleReport = (e) => {
    setComment(e.target.value);
  };
  const dispatch = useDispatch()

  const submitReport = async () => {
    try {
      handleSubmit();
      dispatch(submitReportForQns({ uid, qid, comment }));
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = (data) => {
    console.log("form data: ", data);
    submitReport();
  };

  const onError = (error) => {
    console.error("form error: ", error);
  };

  return (
    <>
      <button
        className="report-btn action-btn"
        onClick={handleShow}
        disabled={isSubmitted}
        style={{ alignText: 'center', padding: '0px 10px' }}
      >
        <div className="d-flex align-items justify-center">
          <div className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
            >
              <path d="M8 5v14l11-7z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
          <div>
            <span>Report</span>
          </div>
        </div>
      </button>
      <Modal centered show={show} onHide={handleClose} animation={false}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit, onError)}>
          <Modal.Header closeButton>
            <Modal.Title>Report Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>
              Let us know what is wrong with the question! We'll review it
              again! (200 characters)
            </Form.Label>
            <Form.Control
              as="textarea"
              maxLength={200}
              {...form.register("comment", {
                required: {
                  value: true,
                  message: "This field is required",
                },
                maxLength: {
                  value: 200,
                  message: "Max character count exceeded",
                },
                onChange: handleReport,
              })}
            />
            <span>
              Character(s) remaining: {200 - form.watch("comment").length}
            </span>
            <br />
            {form.formState?.errors?.comment && (
              <span className="text-danger">
                {form.formState?.errors?.comment.message}
              </span>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <ButtonTypeTwo
              text="CLOSE"
              action={handleClose} 
            />
            <ButtonTypeThree
              text="SUBMIT"
              action={onSubmit}
              disabled={isSubmitted}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
