import { createSlice } from "@reduxjs/toolkit";
import { getSurveyQuestions, submitSurveyResponses } from "../thunks/fetchDataThunk";

const initialState = {
    questions: [],
    surveyResponse: {},
    isSuccess: false,
    currentSurvey: {}
}

const surveyAppSlice = createSlice({
    name: "surveyApp",
    initialState,
    reducers:{
        setSurveyResponseByQID: (state, action) => {
            state.surveyResponse[action.payload.qid] = action.payload.value
        },
        setCurrentSurvey: (state, action) => {
            state.currentSurvey = action.payload
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSurveyQuestions.fulfilled, (state, action) => {
                const surveyQuestions = action.payload
                state.questions = surveyQuestions
                console.log("survey questions received", state.questions)
            })
            
        builder
            .addCase(submitSurveyResponses.fulfilled, (state) => {
                state.isSuccess = true
                console.log("survey responses submitted")
            })
    }
})

export const { setCurrentSurvey, setSurveyResponseByQID, resetState } = surveyAppSlice.actions
export default surveyAppSlice.reducer