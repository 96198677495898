import { createAsyncThunk } from "@reduxjs/toolkit";
import { getQuestionsByTopic, getAnswersForTest, getConfigs, getExamPaperByTopic, submitExamAnswer, reportQuestion, retrieveUserProfile, getSurveyQuestionsByCode, submitSurveyResponse, submitContactForm } from "../../services/apiServices";

export const gradeQuestionsForTest = createAsyncThunk('testApp/gradeQuestionsByTopic', getAnswersForTest)
export const getQuestionsForTest = createAsyncThunk('testApp/getQuestionsByTopic', getQuestionsByTopic)


// Same as above but diff const name. might want to refactor after
export const getPracticeModeTest = createAsyncThunk("practiceMode/getPracticeModeTestPaper", getQuestionsByTopic)
export const getPracticeModeAnswer = createAsyncThunk("practiceMode/getPracticeModeAnswer", getAnswersForTest)


export const getExamModeTest = createAsyncThunk("examMode/getExamModeTestPaper", getExamPaperByTopic)
export const submitExamModeAnswer = createAsyncThunk("examMode/getExamModeAnswer", submitExamAnswer)


// Async thunk
export const getConfigsForApp = createAsyncThunk("testApp/getConfigs", getConfigs);

export const submitReportForQns = createAsyncThunk("submitReport", reportQuestion)

export const getSurveyQuestions = createAsyncThunk("surveyApp/getSurveyQuestions", getSurveyQuestionsByCode)

export const submitSurveyResponses = createAsyncThunk("surveyApp/submitSurveyResponses", submitSurveyResponse)

export const getUserProfile = createAsyncThunk("auth/getUserProfile", retrieveUserProfile)

export const submitContactResponse = createAsyncThunk("contactApp/submitContactResponse", submitContactForm) 