import { useNavigate } from "react-router-dom";

export default function TitleContactCard({data, disabled=false}) {
    const navigate = useNavigate()

    const navigateContact = () => {
        navigate("/contact")
    }

    return (
        <button
            className={`action-card-div ${
            disabled ? "disable" : ""
            } d-flex flex-column gap-1`}
            onClick={navigateContact}
        >
            <h4 className="action-card-title card-title">LET US KNOW 💻</h4>
            <p className="mt-1">Encountered an issue? Spotted a bug 🐞? Activate our capsulebank Pest Control team! 😎</p>
            <p className="mt-1">Have suggestions to enhance our app? We're all 👂! </p>
         </button>  
     )
}