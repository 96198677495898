//RestartTestBtn and ReturnHomeBtn
import Button from "react-bootstrap/Button";

export function ButtonTypeOne({
  text,
  style = {},
  type = "button",
  action = () => {},
}) {
  return (
    <div className="btn-container">
      <Button
        type={type}
        className="action-btn"
        style={{
          width: "200px",
          border: "2px solid",
          borderColor: "rgba(128, 128, 128, 0.255)",
          ...style,
        }}
        variant="light"
        onClick={action}
      >
        {text}
      </Button>
    </div>
  );
}
